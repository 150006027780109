/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import fetchService from '../../services/fetch.service'

const initialState = {
  loaded: false,
  expireAt: null,
  annee: [],
  stades: [],
  reservationStatus: [],
  referentials: [],
  entityParameters: [],
  departements: [],
  typeEntite: [],
}

// initial state
const state = () => (initialState)

// getter
const getters = {
  stades(state, culture = null) {
    if (culture) {
      return state.stades.filter((item) => item.culture.id === culture.id)
    }
    return state.stades
  },
  parameters: (state) => (uids = null) => {
    if (uids) {
      return state.entityParameters.filter((item) => uids.includes(item.uid))
    }
    return state.entityParameters
  },
}

// actions
const actions = {

  setReferentials({ commit }) {
    return new Promise((success, reject) => {
      fetchService.get('dictionnaire/referentiel', { limit: 0 })
        .then((response) => {
          const referentials = {}
          const { data } = response

          // eslint-disable-next-line
          for (let i = 0; i < data.length; i++) {
            referentials[data[i].uid] = data[i].id
          }

          localStorage.removeItem('referentials')
          localStorage.setItem('referentials', JSON.stringify(referentials))

          commit('setReferentials', referentials)

          success()
        }).catch((error) => {
          reject(error)
        })
    })
  },
  setData({ commit, state, rootState }) {
    return new Promise((success, reject) => {
      console.log('App is loading data')

      const currentEntityId = rootState.auth.user.data.entite.id

      Promise.all([
        fetchService.get(`dictionnaire/referentiel/${state.referentials.anneerecolte}/valeur`).then((response) => {
          commit('setAnnee', response.data)
        }),

        fetchService.get('dictionnaire/stade', {
          limit: 0,
          sort: 'ordre.ASC',
        }).then((response) => {
          commit('setStade', response.data)
        }),

        fetchService.get('dictionnaire/statut/reservation', {
          limit: 0,
        }).then((response) => {
          commit('setReservationStatus', response.data)
        }),

        fetchService.get('dictionnaire/type/entite', {
          limit: 0,
        }).then((response) => {
          commit('setTypeEntite', response.data)
        }),

        fetchService.get(`entite/${currentEntityId}/parametre`)
          .then((response) => {
            commit('setEntityParameters', response.data)
          }),
      ]).then(() => {
        commit('setDepartements')
        commit('setLoaded')
        console.log('data loaded !')
        success()
      }).catch((error) => {
        reject(error)
      })
    })
  },
  reset({ commit }) {
    commit('reset')
  },
}

// mutations
const mutations = {
  reset(state) {
    const date = new Date()
    date.setSeconds(date.getSeconds() - 1800)
    state.loaded = false
    state.expireAt = date.getTime()
  },
  setReferentials(state, data) {
    state.referentials = data
  },
  setAnnee(state, data) {
    const date = new Date()
    date.setSeconds(date.getSeconds() + 1800)
    state.annee = data
    state.expireAt = date.getTime()
  },
  setStade(state, data) {
    state.stades = data
  },
  setReservationStatus(state, data) {
    state.reservationStatus = data
  },
  setEntityParameters(state, data) {
    state.entityParameters = data
  },
  setTypeEntite(state, data) {
    state.typeEntite = data
  },
  setLoaded(state) {
    state.loaded = true
  },
  setDepartements(state) {
    state.loaded = true
    state.departements = [
      {
        value: 'Ain',
        key: '01',
      },
      {
        value: 'Aisne',
        key: '02',
      },
      {
        value: 'Allier',
        key: '03',
      },
      {
        value: 'Alpes-de-Haute-Provence',
        key: '04',
      },
      {
        value: 'Hautes-Alpes',
        key: '05',
      },
      {
        value: 'Alpes-Maritimes',
        key: '06',
      },
      {
        value: 'Ardèche',
        key: '07',
      },
      {
        value: 'Ardennes',
        key: '08',
      },
      {
        value: 'Ariège',
        key: '09',
      },
      {
        value: 'Aube',
        key: '10',
      },
      {
        value: 'Aude',
        key: '11',
      },
      {
        value: 'Aveyron',
        key: '12',
      },
      {
        value: 'Bouches-du-Rhône',
        key: '13',
      },
      {
        value: 'Calvados',
        key: '14',
      },
      {
        value: 'Cantal',
        key: '15',
      },
      {
        value: 'Charente',
        key: '16',
      },
      {
        value: 'Charente-Maritime',
        key: '17',
      },
      {
        value: 'Cher',
        key: '18',
      },
      {
        value: 'Corrèze',
        key: '19',
      },
      {
        value: "Côte-d'Or",
        key: '21',
      },
      {
        value: "Côtes-d'Armor",
        key: '22',
      },
      {
        value: 'Creuse',
        key: '23',
      },
      {
        value: 'Dordogne',
        key: '24',
      },
      {
        value: 'Doubs',
        key: '25',
      },
      {
        value: 'Drôme',
        key: '26',
      },
      {
        value: 'Eure',
        key: '27',
      },
      {
        value: 'Eure-et-Loir',
        key: '28',
      },
      {
        value: 'Finistère',
        key: '29',
      },
      {
        value: 'Corse-du-Sud',
        key: '2A',
      },
      {
        value: 'Haute-Corse',
        key: '2B',
      },
      {
        value: 'Gard',
        key: '30',
      },
      {
        value: 'Haute-Garonne',
        key: '31',
      },
      {
        value: 'Gers',
        key: '32',
      },
      {
        value: 'Gironde',
        key: '33',
      },
      {
        value: 'Hérault',
        key: '34',
      },
      {
        value: 'Ille-et-Vilaine',
        key: '35',
      },
      {
        value: 'Indre',
        key: '36',
      },
      {
        value: 'Indre-et-Loire',
        key: '37',
      },
      {
        value: 'Isère',
        key: '38',
      },
      {
        value: 'Jura',
        key: '39',
      },
      {
        value: 'Landes',
        key: '40',
      },
      {
        value: 'Loir-et-Cher',
        key: '41',
      },
      {
        value: 'Loire',
        key: '42',
      },
      {
        value: 'Haute-Loire',
        key: '43',
      },
      {
        value: 'Loire-Atlantique',
        key: '44',
      },
      {
        value: 'Loiret',
        key: '45',
      },
      {
        value: 'Lot',
        key: '46',
      },
      {
        value: 'Lot-et-Garonne',
        key: '47',
      },
      {
        value: 'Lozère',
        key: '48',
      },
      {
        value: 'Maine-et-Loire',
        key: '49',
      },
      {
        value: 'Manche',
        key: '50',
      },
      {
        value: 'Marne',
        key: '51',
      },
      {
        value: 'Haute-Marne',
        key: '52',
      },
      {
        value: 'Mayenne',
        key: '53',
      },
      {
        value: 'Meurthe-et-Moselle',
        key: '54',
      },
      {
        value: 'Meuse',
        key: '55',
      },
      {
        value: 'Morbihan',
        key: '56',
      },
      {
        value: 'Moselle',
        key: '57',
      },
      {
        value: 'Nièvre',
        key: '58',
      },
      {
        value: 'Nord',
        key: '59',
      },
      {
        value: 'Oise',
        key: '60',
      },
      {
        value: 'Orne',
        key: '61',
      },
      {
        value: 'Pas-de-Calais',
        key: '62',
      },
      {
        value: 'Puy-de-Dôme',
        key: '63',
      },
      {
        value: 'Pyrénées-Atlantiques',
        key: '64',
      },
      {
        value: 'Hautes-Pyrénées',
        key: '65',
      },
      {
        value: 'Pyrénées-Orientales',
        key: '66',
      },
      {
        value: 'Bas-Rhin',
        key: '67',
      },
      {
        value: 'Haut-Rhin',
        key: '68',
      },
      {
        value: 'Rhône',
        key: '69',
      },
      {
        value: 'Haute-Saône',
        key: '70',
      },
      {
        value: 'Saône-et-Loire',
        key: '71',
      },
      {
        value: 'Sarthe',
        key: '72',
      },
      {
        value: 'Savoie',
        key: '73',
      },
      {
        value: 'Haute-Savoie',
        key: '74',
      },
      {
        value: 'Paris',
        key: '75',
      },
      {
        value: 'Seine-Maritime',
        key: '76',
      },
      {
        value: 'Seine-et-Marne',
        key: '77',
      },
      {
        value: 'Yvelines',
        key: '78',
      },
      {
        value: 'Deux-Sèvres',
        key: '79',
      },
      {
        value: 'Somme',
        key: '80',
      },
      {
        value: 'Tarn',
        key: '81',
      },
      {
        value: 'Tarn-et-Garonne',
        key: '82',
      },
      {
        value: 'Var',
        key: '83',
      },
      {
        value: 'Vaucluse',
        key: '84',
      },
      {
        value: 'Vendée',
        key: '85',
      },
      {
        value: 'Vienne',
        key: '86',
      },
      {
        value: 'Haute-Vienne',
        key: '87',
      },
      {
        value: 'Vosges',
        key: '88',
      },
      {
        value: 'Yonne',
        key: '89',
      },
      {
        value: 'Territoire de Belfort',
        key: '90',
      },
      {
        value: 'Essonne',
        key: '91',
      },
      {
        value: 'Hauts-de-Seine',
        key: '92',
      },
      {
        value: 'Seine-Saint-Denis',
        key: '93',
      },
      {
        value: 'Val-de-Marne',
        key: '94',
      },
      {
        value: "Val-d'Oise",
        key: '95',
      },
      {
        value: 'Guadeloupe',
        key: '971',
      },
      {
        value: 'Martinique',
        key: '972',
      },
      {
        value: 'Guyane',
        key: '973',
      },
      {
        value: 'La Réunion',
        key: '974',
      },
      {
        value: 'Mayotte',
        key: '976',
      },
    ]
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
