export default {
  data() {
    return {
      // refresh variables
      refreshing: false,
      registration: null,
      updateExists: false,
    }
  },

  created() {
    // Listen for our custom event from the SW registration
    // Prevent multiple refreshes
    if (navigator.serviceWorker) {
      document.addEventListener('swUpdated', this.updateAvailable, { once: true })
    }
  },

  methods: {
    // Store the SW registration so we can send it a message
    // We use `updateExists` to control whatever alert, toast, dialog, etc we want to use
    // To alert the user there is an update they need to refresh for
    async updateAvailable(event) {
      this.registration = event.detail

      if (this.$store.state?.auth?.user?.loggedIn) {
        this.updateExists = true
      } else {
        this.emitter.emit('open-loader')
        await this.forceUpdateSW()
        this.emitter.emit('close-loader')
      }
    },

    async forceUpdateSW() {
      if ('serviceWorker' in navigator) {
        const registrations = await navigator.serviceWorker.getRegistrations()
        // eslint-disable-next-line
        for (const registration of registrations) {
          registration.update()
        }
      }
    },

    // Called when the user accepts the update
    async refreshApp() {
      this.emitter.emit('open-loader')
      await this.forceUpdateSW()

      this.updateExists = false

      this.$store.dispatch('app/setReferentials').then(() => {
        this.$store.dispatch('app/setData').then(() => {
          this.emitter.emit('close-loader')
          window.location.reload(true)
        }, () => {
          this.emitter.emit('close-loader')
          window.location.reload(true)
        })
      })
    },
  },
}
