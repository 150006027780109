import { createStore } from 'vuex'
import VuexPersistence from 'vuex-persist'

import app from './modules/app'
import auth from './modules/auth'
import roleProfile from './modules/roleProfile'
import backInTime from './modules/backInTime'
import protocol from './modules/protocol'
import io from './modules/import_export'
import plan from './modules/plan'

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
})

export default createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    roleProfile,
    app,
    protocol,
    backInTime,
    io,
    plan,
  },
  plugins: [vuexLocal.plugin],
})
