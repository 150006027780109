<template>
  <div class="navbar">
    <!-- LOGO -->
    <router-link v-if="!isOnline"
      :to="{ name: 'home' }"
      class="navbar-logo">
      <img class="navbar-logo-logotype"
        alt="Le logo de OSS"
        src="../../assets/logo-oss-desktop.svg" />
    </router-link>
    <a href="#"
      v-if="isOnline"
      @click="toHome()"
      class="navbar-logo"
      v-ripple>
      <img class="navbar-logo-logotype"
        alt="Le logo de OSS"
        src="../../assets/logo-oss-desktop.svg" />
    </a>

    <!-- NAVBAR MENU -->
    <nav class="navbar-menu"
      ref="navbar">
      <ul class="menu">
        <MenuItem v-if="!isOnline"
          :item-data="{
            label: 'Accueil',
            route: '/',
            iconName: 'home',
          }" />
        <MenuItem v-if="isOnline"
          @click="toHome()"
          :item-data="{
            label: 'Accueil',
            iconName: 'home',
          }" />

        <li class="menu-separator"></li>

        <MenuItem :item-data="{
          label: 'Protocoles d\'essai',
          isDropdown: true,
          iconName: 'calendar',
        }">
        <MenuItemDropdown :menu-data="
          [
            {
              label: 'Protocoles d\'essai',
              isBack: true,
              iconName: 'chevron-big-left',
            },
          ].concat(protocole)
        ">
        </MenuItemDropdown>
        </MenuItem>

        <MenuItem :item-data="{
          label: 'Essais',
          isDropdown: true,
          iconName: 'science',
        }">
        <MenuItemDropdown :menu-data="
          [
            {
              label: 'Essais',
              isBack: true,
              iconName: 'chevron-big-left',
            },
          ].concat(essais)
        ">
        </MenuItemDropdown>
        </MenuItem>

        <MenuItem :item-data="{
          label: 'Produits',
          isDropdown: true,
          iconName: 'tag',
        }">
        <MenuItemDropdown :menu-data="
          [
            {
              label: 'Produits',
              isBack: true,
              iconName: 'chevron-big-left',
            },
          ].concat(produit)
        ">
        </MenuItemDropdown>
        </MenuItem>

        <li class="menu-separator"></li>

        <MenuItem v-if="utilisateur?.label"
          :item-data="utilisateur" />

        <MenuItem v-if="entite"
          :item-data="{
            label: 'Mon entité',
            isDropdown: true,
            iconName: 'cogs',
          }">
        <MenuItemDropdown :menu-data="
          [
            {
              label: 'Mon entité',
              isDropdown: false,
              isBack: true,
              iconName: 'chevron-big-left',
            },
          ].concat(entite)
        ">
        </MenuItemDropdown>
        </MenuItem>

        <MenuItem v-if="administration"
          :item-data="{
            label: 'Administration',
            isDropdown: true,
            iconName: 'barchart',
          }">
        <MenuItemDropdown :menu-data="
          [
            {
              label: 'Administration',
              isDropdown: false,
              isBack: true,
              iconName: 'chevron-big-left',
            },
          ].concat(administration)
        ">
        </MenuItemDropdown>
        </MenuItem>

        <MenuItem v-if="helperService.currentEnv('dev')"
          :item-data="{
            label: 'Styleguide',
            isDropdown: true,
            iconName: 'grin-tongue',
          }">
        <MenuItemDropdown :menu-data="[
          {
            label: 'Styleguide',
            isDropdown: false,
            isBack: true,
            iconName: 'chevron-big-left',
          },
          {
            label: 'Colors',
            route: '/styleguide/colors',
          },
          {
            label: 'Typography',
            route: '/styleguide/typography',
          },
          {
            label: 'Icons',
            route: '/styleguide/icons',
          },
          {
            label: 'Buttons',
            route: '/styleguide/buttons',
          },
          {
            label: 'Layout',
            route: '/styleguide/layout',
          },
          {
            label: 'Forms',
            route: '/styleguide/forms',
          },
          {
            label: 'Forms advanced',
            route: '/styleguide/forms-advanced',
          },
          {
            label: 'Lists',
            route: '/styleguide/lists',
          },
          {
            label: 'Tables',
            route: '/styleguide/tables',
          },
          {
            label: 'Misc',
            route: '/styleguide/misc',
          },
        ]">
        </MenuItemDropdown>
        </MenuItem>
      </ul>
    </nav>
    <div v-if="currentVersion" class="version-number"><em>v{{ currentVersion }}</em></div>

  </div>
</template>

<script>
import { ref } from 'vue'
import MenuItem from '@/components/layout/MenuItem.vue'
import MenuItemDropdown from '@/components/layout/MenuItemDropdown.vue'

export default {
  name: 'NavBar',

  components: {
    MenuItem,
    MenuItemDropdown,
  },

  computed: {
    loggedIn() {
      return this.$store.state.auth.user.loggedIn
    },

    user() {
      return this.$store.state.auth.user.data
    },
  },

  watch: {
    loggedIn(newloggedIn, oldloggedIn) {
      // Our fancy notification (2).
      console.log(`We have ${newloggedIn}  ${oldloggedIn} fruits now, yay!`)
    },

    // Active item's parent detection
    $route() {
      this.$nextTick(() => {
        // Remove active class on all parents
        const parentItems = document.querySelectorAll('.menu-item--dropdown-toggle')
        parentItems.forEach((parentItem) => {
          parentItem.classList.remove('menu-item--dropdown-has-active-child')
        })
        // Add active class if parent has an active child
        const activeRouterLink = document.querySelector('.router-link-exact-active')
        if (activeRouterLink) {
          const parentItem = activeRouterLink.closest('.menu-item--dropdown-toggle')
          if (parentItem) {
            parentItem.classList.add('menu-item--dropdown-has-active-child')
          }
        }
      })
    },
  },

  setup() {
    const navbar = ref(null)

    return {
      navbar,
    }
  },

  data() {
    return {
      utilisateur: {},
      protocole: [],
      essais: [],
      entite: [],
      administration: [],
      styleguide: [],
      produit: [],
      isOnline: navigator.onLine,
      currentVersion: null,
    }
  },

  mounted() {
    const $navbar = this.navbar
    $navbar.addEventListener('scroll', () => {
      this.emitter.emit('navbar-scroll')
    })

    this.updateVersion()

    this.initArray()
    this.getSubItems()
  },

  methods: {
    updateVersion() {
      this.currentVersion = process.env.VERSION
    },
    toHome() {
      window.location.href = '/'
    },
    initArray() {
      this.utilisateur = {}
      this.protocole = []
      this.essais = []
      this.entite = []
      this.administration = []
      this.styleguide = []
      this.produit = []
    },
    getSubItems() {
      // utilisateur
      if (
        // eslint-disable-next-line
        this.helperService.userHasPermission(
          'utilisateur_list_full, utilisateur_list_entite',
          'OR',
        )
      ) {
        this.utilisateur = {
          label: 'Utilisateurs',
          route: '/utilisateurs',
          isDropdown: false,
          iconName: 'users',
        }
      }

      // essais
      if (this.helperService.userHasPermission('essai_list')) {
        this.essais.push({
          label: 'Essais de mon entité',
          route: { name: 'essais' },
        })
      }

      if (this.helperService.userHasPermission('essai_list')
        && this.$store.state.auth.user.data.entite.type.uid !== 'COOPERATIVE') {
        this.essais.push({
          label: 'Essais de mes entités filles',
          route: { name: 'essaisChildren' },
        })
      }

      if (this.helperService.userHasPermission('essai_list')) {
        this.essais.push({
          label: 'Bibliothèque de plans',
          route: { name: 'essaisLibrary' },
        })
      }

      if (this.helperService.userHasPermission('parcelle_list')) {
        this.essais.push({
          label: 'Gestion des parcelles',
          route: { name: 'parcels' },
        })
      }
      // protocole
      if (this.helperService.userHasPermission('protocole_list')) {
        this.protocole.push({
          label: 'Protocoles de mon entité',
          route: { name: 'protocols' },
        })
      }
      if (this.helperService.userHasPermission('protocole_shared')) {
        this.protocole.push({
          label: 'Partagés avec mon entité',
          route: { name: 'protocolsShare' },
        })
      }

      // produits
      if (this.helperService.userHasPermission('produit_list')) {
        this.produit.push({
          label: 'Santé végétale',
          route: { name: 'healths' },
        })
        this.produit.push({
          label: 'Fertilisants',
          route: { name: 'fertilizers' },
        })
        this.produit.push({
          label: 'Semences',
          route: { name: 'seeds' },
        })
      }
      if (this.helperService.userHasPermission('fournisseur_add,fournisseur_edit', 'OR')) {
        this.produit.push({
          label: 'Fournisseurs',
          route: { name: 'suppliers' },
        })
      }

      // entite
      if (this.helperService.userHasPermission('entiteparametre_show')) {
        this.entite.push({
          label: 'Paramètres',
          route: { name: 'myEntityParameter' },
        })
      }

      this.entite.push({
        label: 'Informations',
        route: { name: 'myEntity' },
      })

      if (
        this.helperService.userHasPermission(
          'entite_show,entite_add,entite_edit',
          'OR',
        )
      ) {
        this.administration.push({
          label: 'Entités',
          route: { name: 'entities' },
        })
      }
      if (
        this.helperService.userHasPermission('valeur_oadd,valeur_oedit', 'OR')
      ) {
        this.administration.push({
          label: 'Référentiels',
          route: { name: 'references' },
        })
      }

      if (
        this.helperService.userHasPermission('profil_add,profil_edit', 'OR')
      ) {
        this.administration.push({
          label: 'Profils de droits',
          route: { name: 'roleProfiles' },
        })
      }

      if (
        this.helperService.userHasPermission(
          'etiquettemodele_add , etiquettemodele_edit',
          'OR',
        )
      ) {
        this.administration.push({
          label: "Modèle d'étiquette",
          route: { name: 'modelsLabel' },
        })
      }

      if (
        this.helperService.userHasPermission('dictionariesetiquettegabarit_add', 'dictionariesetiquettegabarit_edit', 'OR')
      ) {
        this.administration.push({
          label: "Gabarit d'étiquette",
          route: { name: 'templatesLabel' },
        })
      }

      if (
        this.helperService.userHasPermission(
          'variable_add , variable_edit',
          'OR',
        )
      ) {
        this.administration.push({
          label: 'Variables',
          route: { name: 'variables' },
        })
      }

      if (this.helperService.userHasPermission('log_list')) {
        this.administration.push({
          label: 'Historique des actions',
          route: { name: 'logs' },
        })
      }

      if (this.helperService.userHasPermission('utilitiesmail_list')) {
        this.administration.push({
          label: 'Mails envoyés',
          route: { name: 'mails' },
        })
      }
    },

  },
}
</script>

<style lang="scss" scoped>
/* NAVBAR */

// NAVBAR

.site-aside>.navbar {
  @include bp($breakpoint-sidebar-full) {
    height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: white;
    @include shadow(1);
  }
}

// NAVBAR MENU

.navbar {
  >.navbar-menu {
    @include bp($breakpoint-sidebar-full) {
      height: 100%;
      overflow-y: auto;
      flex-grow: 1;
    }
  }
}

// NAVBAR LOGO (desktop only)

.navbar-logo {
  display: none;

  @include bp($breakpoint-sidebar-full) {
    display: block;
    text-align: center;
    @include v-padding($gutter);

    .navbar-logo-logotype {
      @include h-margin(auto);
    }
  }
}

// NAVBAR SEPARATOR

.menu-separator {
  height: 1px;
  background-color: $color-gray-lighter;

  .navbar-menu & {
    @include bp($breakpoint-sidebar-full) {
      @include v-margin($gutter-half);
    }
  }
}

.version-number {
  @include v-padding($gutter-half);
  @include h-padding($gutter-quarter);
  @include bp($breakpoint-sidebar-full) {
    @include h-padding($gutter-half);
  }
}
</style>
