// Vendor libs
import mitt from 'mitt'
import ripple from 'vue3-whr-ripple-directive'
import vueCodeHighlight from 'vue-code-highlight'
import { dragscrollNext } from 'vue-dragscroll'

// Vue app
import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'

// Services
import FetchService from './services/fetch.service'
import FormService from './services/form.service'
import HelperService from './services/helper.service'
import FileService from './services/file.service'
import YupService from './services/yup.service'
import GroupingService from './services/grouping.service'

// Job Services
import ProductService from './services/product.service'
import ProtocolService from './services/protocol.service'
import TaskService from './services/task.service'
import NotationService from './services/notation.service'

// Router and store
import router from './router'
import store from './store'

// Create the vue app
const app = createApp(App)
// Make the pub-sub event bus global
// See: https://github.com/developit/mitt
const emitter = mitt()
app.config.globalProperties.emitter = emitter

// Make the fetcher service global
app.config.globalProperties.fetchService = FetchService
app.config.globalProperties.fetchService.setEmitter(emitter)
app.config.globalProperties.fetchService.setStore(store)
app.config.globalProperties.fetchService.setRouter(router)

// Make the form service global
app.config.globalProperties.formService = new FormService()
app.config.globalProperties.formService.setEmitter(emitter)

// Make the helper service global
app.config.globalProperties.helperService = HelperService
app.config.globalProperties.helperService.setEmitter(emitter)
app.config.globalProperties.helperService.setStore(store)
app.config.globalProperties.helperService.setFetchService(FetchService)

// Make the helper service global
app.config.globalProperties.productService = ProductService
app.config.globalProperties.productService.setEmitter(emitter)
app.config.globalProperties.productService.setStore(store)

// Make the helper service global
app.config.globalProperties.protocolService = ProtocolService
app.config.globalProperties.protocolService.setEmitter(emitter)
app.config.globalProperties.protocolService.setStore(store)

// Make the helper service global
app.config.globalProperties.taskService = TaskService
app.config.globalProperties.taskService.setEmitter(emitter)
app.config.globalProperties.taskService.setStore(store)
app.config.globalProperties.taskService.setRouter(router)

// Make the file service global
app.config.globalProperties.fileService = FileService
app.config.globalProperties.fileService.setEmitter(emitter)
app.config.globalProperties.fileService.setStore(store)

// Make the file service global
app.config.globalProperties.notationService = NotationService
app.config.globalProperties.notationService.setStore(store)
app.config.globalProperties.notationService.setEmitter(emitter)
app.config.globalProperties.notationService.setRouter(router)

app.config.globalProperties.groupingService = GroupingService
app.config.globalProperties.groupingService.setStore(store)
app.config.globalProperties.groupingService.setEmitter(emitter)
app.config.globalProperties.groupingService.setRouteur(router)
// Make the yup service global
app.config.globalProperties.yup = YupService

// Configure and make the ripple effect directive global
// See: https://www.npmjs.com/package/vue3-whr-ripple-directive/v/1.0.0
ripple.color = 'rgba(255, 255, 255, 0.35)'
// ripple.zIndex = 55;
app.directive('ripple', ripple)

// Code coloration
app.use(vueCodeHighlight) // Registers the v-highlight directive

// Click to scroll directive
app.directive('dragscroll', dragscrollNext)

// Register a global custom directive called `v-click-outside`
// It fires the function passed as value, on click outside the element
// Ex:   <div class="tool-dropdown" v-click-outside="closeDropdown">

app.directive('click-outside', {
  beforeMount(el, binding) {
    /* eslint-disable-next-line no-param-reassign */
    el.clickOutsideEvent = (event) => {
      if (!(el === event.target || el.contains(event.target))) {
        // vnode.context[binding.expression](event) // Vue 2 syntax (I suppose)
        binding.value(event)
      }
    }
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unmounted(el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  },
})

// App main bootstrapping
app.use(store)
app.use(router)

app.mount('#app')
