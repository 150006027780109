/* eslint no-shadow: ["error", { "allow": ["state"] }] */

const initialState = {
  errors: [],
  sheets: [],
  jeton: null,
  disabled: [],
  enabled: [],
  flatVariables: [],
  analysisResults: [],
  tableSheets: [],
}

// initial state
const state = () => (initialState)

// actions
const actions = {
  setJeton({ commit }, data) {
    commit('setJeton', data)
  },
  setErrors({ commit }, data) {
    commit('setErrors', data)
  },
  setSheets({ commit }, data) {
    commit('setSheets', data)
  },
  setDisabled({ commit }, data) {
    commit('setDisabled', data)
  },
  clear({ commit }) {
    commit('clearState')
  },
  setAnalysisResults({ commit }, data) {
    commit('setAnalysisResults', data)
  },
}

// mutations
const mutations = {
  clearState(state) {
    state.errors = []
    state.sheets = []
    state.jeton = null
    state.disabled = []
    state.enabled = []
    state.flatVariables = []
    state.analysisResults = []
    state.tableSheets = []
  },
  setErrors(state, data) {
    state.errors = data
  },
  setSheets(state, data) {
    console.log('setSheets')
    data.forEach((d, i) => {
      data[i].donnees.forEach((dd, j) => {
        dd.unshift(`<div class="table-index">${j + 1}</div>`)
      })
      state.disabled[i] = []
      state.enabled[i] = []
    })
    state.sheets = data
    mutations.setSheetsForTables(state)
  },
  setSheetsForTables(state) {
    console.log('setSheetsForTables')

    const sheetsForTables = []
    const datas = state.sheets
    datas.forEach((sheet, index) => {
      let toPush = []
      if (datas[index].donnees.length) {
        const dataLength = datas[index].donnees.at(0).length
        const ellips = new Array(dataLength)
        ellips.fill('…', 0, dataLength)
        // ellips.fill(null, 1, dataLength)
        toPush = [datas[index].donnees.at(0), ellips, datas[index].donnees.at(-1)]
      }
      sheetsForTables.push(toPush)
    })
    state.tableSheets = sheetsForTables
  },
  setJeton(state, data) {
    console.log('setJeton')
    state.jeton = data
  },
  setDisabled(state, data) {
    const old = state.enabled[data.sheet][data.column]
    state.enabled[data.sheet][data.column] = data.variable ?? null

    for (let j = 3; j < state.sheets[data.sheet].en_tetes.length; j += 1) {
      if (parseInt(data.column, 10) !== j) {
        const disabled = state.disabled[data.sheet][j] || []
        state.disabled[data.sheet][j] = disabled.filter((item) => item !== old)
        if (data.variable) {
          state.disabled[data.sheet][j].push(data.variable)
        }
      }
    }

    state.sheets.forEach((s, i) => {
      if (parseInt(i, 10) !== parseInt(data.sheet, 10)) {
        for (let j = 3; j < s.en_tetes.length; j += 1) {
          const disabled = state.disabled[i][j] || []
          state.disabled[i][j] = disabled.filter((item) => item !== old)
          if (data.variable) {
            state.disabled[i][j].push(data.variable)
          }
        }
      }
    })
  },
  setAnalysisResults(state, data) {
    const results = []
    data.forEach((sheet, index) => {
      Object.keys(sheet.variables).forEach((i) => {
        const v = sheet.variables[i] ?? []
        v.sheet = index
        v.column = i
        v.errors = []
        v.nbr_errors = 0
        Object.keys(sheet.erreurs.en_tetes).forEach((column) => {
          if (parseInt(i, 10) === parseInt(column, 10)) {
            v.errors.push({ errors: sheet.erreurs.en_tetes[column] })
            v.nbr_errors += sheet.erreurs.en_tetes[column].length
            // eslint-disable-next-line
            v.errors[v.errors.length ? v.errors.length - 1 : 0].id = v.errors.length ? v.errors.length : 0
          }
        })
        Object.keys(sheet.erreurs.donnees).forEach((row) => {
          Object.keys(sheet.erreurs.donnees[row]).forEach((column) => {
            if (parseInt(i, 10) === parseInt(column, 10)) {
              v.errors.push({ errors: sheet.erreurs.donnees[row][column] })
              v.nbr_errors += sheet.erreurs.donnees[row][column].length
              // eslint-disable-next-line
              //v.errors[v.errors.length ? v.errors.length - 1 : 0].moda = sheet.donnees[row][0]
              // eslint-disable-next-line
              v.errors[v.errors.length ? v.errors.length - 1 : 0].coos = sheet.donnees[row][1]
              v.errors[v.errors.length ? v.errors.length - 1 : 0].organe = sheet.donnees[row][3] ?? '---'
              v.errors[v.errors.length ? v.errors.length - 1 : 0].echantillon = sheet.donnees[row][4] ?? '---'
              v.errors[v.errors.length ? v.errors.length - 1 : 0].value = sheet.donnees[row][column]
              v.errors[v.errors.length ? v.errors.length - 1 : 0].id = v.errors.length ? v.errors.length : 0
            }
          })
        })
        results.push(v)
      })
    })
    state.analysisResults = results
  },
}

const getters = {
  variables(state) {
    const sheets = []
    state.sheets.forEach((s) => {
      const variables = s.notations.map((notation) => {
        const date = notation?.evenement?.date_realisation ? new Date(notation?.evenement?.date_realisation) : null
        return {
          designation: notation?.evenement?.designation ?? notation?.evenement?.type?.designation /* 'NOTATION DE LA VIGUEUR EN SORTIE HIVER' */,
          date_realisation: date ? date.toLocaleDateString('fr') : 'Non réalisée'/* '28/10/2023' */,
          variables: notation.variables.map((v) => {
            const { designation } = v
            const cible = v.cible?.valeur ? `[${v.cible.valeur}] ` : ''
            const organe = v.organes[0] ? `[${v.organes[0].designation}]` : ''
            return {
              value: v.id,
              label: `${designation} ${cible}${organe}`, /* 'Capacité à marquer l\'arret hivernal (note) [Acarien des bourgeons du prunier (Acalitus phloeocoptes)]' */
            }
          }),
        }
      })
      variables.forEach((v) => {
        v.variables.forEach((vv) => {
          const notation = `${v.designation}`
          const d = `${v.date_realisation}`
          const variable = vv.label.split('[')[0]
          const cible = vv.label.split('[')[1]?.slice(0, vv.label.split('[')[1].length - 2) ?? ''
          const organe = vv.label.split('[')[2]?.slice(0, vv.label.split('[')[2].length - 1) ?? ''
          state.flatVariables[vv.value] = `Notation : ${notation}<br>Date de réalisation : ${d}<br>Variable : ${variable}`
          state.flatVariables[vv.value] = cible !== '' ? `${state.flatVariables[vv.value]}<br>Cible : ${cible}` : state.flatVariables[vv.value]
          state.flatVariables[vv.value] = organe !== '' ? `${state.flatVariables[vv.value]}<br>Organe : ${organe}` : state.flatVariables[vv.value]
        })
      })
      variables.unshift({
        designation: null,
        date_realisation: null,
        variables: [{
          value: null,
          label: 'Ne pas traiter',
        }],
      })
      sheets.push(variables)
    })

    return sheets
  },
  jeton(state) {
    return state.jeton
  },
  tableSheets(state) {
    return state.tableSheets
  },
  disabled(state) {
    return state.disabled
  },
  enabled(state) {
    return state.enabled
  },
  analysisResults(state) {
    return state.analysisResults
  },
  flatVariables(state) {
    return state.flatVariables
  },
  sheets(state) {
    state.sheets.forEach((s, i) => {
      state.sheets[i].headers = [
        {
          designation: `Feuille #${i + 1}`,
          sub_headers: [
            'Numéro de ligne',
            'Modalités',
            'Coordonnées géographiques',
            'Numéro',
            'Organe',
            'Echantillons',
          ],
          disabled: false,
        },
      ]
      for (let j = 5; j < s.en_tetes.length; j += 1) {
        state.sheets[i].headers.push(
          {
            designation: null,
            sub_headers: [s.en_tetes[j]],
            slotName: `f_${i}_c_${j}`,
            disabled: true,
          },
        )
      }
    })
    return state.sheets
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
